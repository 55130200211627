<template>
    <div class="container">
        <div style="display: flex; justify-content: space-between;">
            <div style="display: flex;">
                <el-input
                    v-model="keyword"
                    style="width: 250px;"
                    placeholder="请输入学校名称"
                >
                </el-input>
                <el-button type="primary" style="margin-left: 20px;">查询</el-button>
            </div>
        </div>
        <!-- table -->
        <div style="margin-top: 20px;">
            <el-table :data="tableList" v-loading="dataListLoading"  style="width: 1490px;" height="600">
                <el-table-column label="序号" width="50" type="index" header-align="center" align="center" fixed="left"></el-table-column>
                <el-table-column v-for="(item, key) in columns" :key="key" :width="item.width || 'auto'" align="center" :label="item.label">
                    <template slot-scope="scope">
                        <div v-if="!item.slot">{{scope.row[item.prop]}}</div>
                        <div v-else>
                            <div v-if="item.prop === 'applyUrl'">
                                <a :href="scope.row[item.prop]" target="_blank">点击查看</a>
                            </div>
                            <div v-if="item.prop === 'review'">
                                <el-button type="text" @click="goReview(scope.row)">进行评审</el-button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div style="margin-top: 20px;">
            <el-pagination
                layout="prev, pager, next"
                :total="total"
                @current-change="currentChange"
                @prev-click="prevClick"
                @next-click="nextClick"
            >
            </el-pagination>
        </div>
        <reviewPage ref="review"/>
    </div>
</template>

<script>
import { $activity } from "@/services";
import $cookies from "vue-cookies";
import {BASE} from '@/services/base'
import reviewPage from "./components/reviewPage.vue";
export default {
    components: {
        reviewPage
    },
    data() {
        return {
            columns:[
                { prop: 'schoolName', label: '学校名称',width:'200' },
                { prop: 'province', label: '省份'},
                { prop: 'address', label: '详细地址',width:'200' },
                { prop: 'applyUrl', label: '报名表',slot:true},
                { prop: 'review', label: '操作',slot:true},
            ],
            dataListLoading:false,
            value1:'',
            tableList:[],
            pageNum:1,
            pageSize:10,
            keyword:'',
            total:0,
            userId:$cookies.get("userInfo").id,
            action: BASE + '/server/common/upload',
            id:'',
            isreport:false
        }
    },
    created() {
        this.getTableList()
    },
    methods:{
        async getTableList() {
            if (!this.dataListLoading) {
                this.dataListLoading = true
                let params = {}
                params.pageNum = this.pageNum
                params.pageSize = this.pageSize
                params.keyword = this.keyword
                params.userId = $cookies.get("userInfo").id
                let res = await $activity.signList(params)
                if (res.code === 200) {
                    this.total = res.data.total
                    this.tableList = res.data.records
                }
                this.dataListLoading = false
            }
        },
        currentChange(e) {
            this.pageNum = e
            this.getTableList()
        },
        prevClick(e) {
            this.pageNum = e
            this.getTableList()
        },
        nextClick(e) {
            this.pageNum = e
            this.getTableList()
        },
        async handleBefore(file) {
            if (!this.isreport) {
                this.$message.error('观测数据必须大于15条!!')
                return false
            }
            if (file.size / 1024 / 1024 > 2) {
                this.$message.error('上传图片不能大于2M')
                this.fileList = []
                return false
            }
            if (file.type !== 'application/pdf') {
                this.$message.error('只能上传pdf文件格式')
                this.fileList = []
                return false
            }
        },
        hanleSuccess(response) {
            if(response.code === 200) {
                let params = {}
                params.id = this.id
                params.reportUrl = response.data.url
                $activity.reportLunwen(params).then(res => {
                    if (res.code === 200) {
                        this.$message.success('上传成功')
                        this.getTableList()
                    } else {
                        this.$message.error('系统错误,请联系管理员')
                    }
                }).catch(() => {
                    this.$message.error('系统错误,请联系管理员')
                })
            }
        },
        async handleClick(row) {
            let params = {}
            params.pageNum = 1
            params.pageSize = 999
            params.date = ''
            params.userId = row.userId
            let res = await $activity.reportList(params)
            if (res.data.records.length < 15) {
                this.isreport = false
            } else {
                this.isreport = true
            }
            if (row.reportUrl) {
                window.open(row.reportUrl, '_blank')
            } else {
                this.id = row.id
            }
        },
        goReview(row) {
            this.$refs.review.setFlag(row)
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    padding: 40px;
}
</style>