<template>
    <el-dialog
        title="评审"
        :visible.sync="flag"
        width="50%"
    >
    <div>
        <el-descriptions class="margin-top" title="学校信息" :column="2" border>
            <el-descriptions-item label="学校名称">{{ schoolInfo.schoolName }}</el-descriptions-item>
            <el-descriptions-item label="省份">{{ schoolInfo.province }}</el-descriptions-item>
            <el-descriptions-item label="指导老师1">{{ schoolInfo.teacher1 }}</el-descriptions-item>
            <el-descriptions-item label="联系方式">{{ schoolInfo.teacher1Contact }}</el-descriptions-item>
            <el-descriptions-item label="指导老师2">{{ schoolInfo.teacher2 }}</el-descriptions-item>
            <el-descriptions-item label="联系方式">{{ schoolInfo.teacher2Contact }}</el-descriptions-item>
            <el-descriptions-item label="观测员">{{ schoolInfo.studentName }}</el-descriptions-item>
            <el-descriptions-item label="联系地址">{{ schoolInfo.address }}</el-descriptions-item>
            <el-descriptions-item label="观测数据得分">
                <el-input-number v-model="numdata" :precision="1" :step="0.1" :min="0" :max="40"></el-input-number>
            </el-descriptions-item>
            <el-descriptions-item label="论文得分">
                <el-input-number v-model="lunnum" :precision="1" :step="0.1" :min="0" :max="60"></el-input-number>
            </el-descriptions-item>
            <el-descriptions-item label="评委意见">
                <el-input
                    type="textarea"
                    :maxlength="200" 
                    show-word-limit
                    :rows="6"
                    v-model="textarea"
                >
                </el-input>
            </el-descriptions-item>
        </el-descriptions>
    </div>

    <!-- 学校观测数据 -->
    <div style="margin-top: 20px;">
        <div class="el-descriptions__title">观测数据</div>
        <el-table :data="tableList" v-loading="dataListLoading"  style="width: 100%;margin-top: 20px;">
            <el-table-column label="观测日期" prop="date" width="200" header-align="center" align="center" fixed="left"></el-table-column>
            <el-table-column label="上午" header-align="center">
                <el-table-column label="记录时间" prop="time1"></el-table-column>
                <el-table-column label="温度°C" prop="tem1"></el-table-column>
                <el-table-column label="湿度%"  prop="humidity1"></el-table-column>
                <el-table-column label="气压hPa" prop="barometer1"></el-table-column>
                <el-table-column label="风向°" prop="windDir1"></el-table-column>
                <el-table-column label="风速m/s" prop="windSpeed1"></el-table-column>
                <el-table-column label="降雨mm" prop="rain1"></el-table-column>
                <el-table-column label="光照(w/m)" prop="solarRadiation1" width="100"></el-table-column>
            </el-table-column>
            <el-table-column label="下午" header-align="center">
                <el-table-column label="记录时间" prop="time2"></el-table-column>
                <el-table-column label="温度°C" prop="tem2"></el-table-column>
                <el-table-column label="湿度%"  prop="humidity2"></el-table-column>
                <el-table-column label="气压hPa" prop="barometer2"></el-table-column>
                <el-table-column label="风向°" prop="windDir2"></el-table-column>
                <el-table-column label="风速m/s" prop="windSpeed2"></el-table-column>
                <el-table-column label="降雨mm" prop="rain2"></el-table-column>
                <el-table-column label="光照(w/m)" prop="solarRadiation2"  width="100"></el-table-column>
            </el-table-column>
            <el-table-column label="观测体会" header-align="center" align="center">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">点击查看</el-button>
                </template>
            </el-table-column>
            <el-table-column label="最美瞬间" header-align="center" align="center">
                <template slot-scope="scope">
                    <a :href="scope.row.photos" target="_blank">点击查看</a>
                </template>
            </el-table-column>
            <el-table-column label="备注" prop="remark" width="200" header-align="center" align="center"></el-table-column>
        </el-table>
    </div>
    <div style="margin-top: 20px;">
        <el-pagination
            layout="prev, pager, next"
            :total="total"
            @current-change="currentChange"
            @prev-click="prevClick"
            @next-click="nextClick"
        >
        </el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="flag = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
    </span>
    </el-dialog>
</template>

<script>
// import dayjs from "dayjs"
import $cookies from "vue-cookies"
import { $activity } from "@/services"
export default {
    data() {
        return {
            flag:false,
            schoolInfo:{},
            numdata:0,
            lunnum:0,
            tableList:[],
            dataListLoading:false,
            pageNum:1,
            pageSize:10,
            total:0,
            date:'',
            textarea:''
        }
    },
    methods:{
        setFlag(row) {
            console.log(row,$cookies.get("userInfo"))
            this.schoolInfo = row
            this.flag = true
            this.getTableData()
        },
        async getTableData() {
            let params = {}
            params.pageNum = this.pageNum
            params.pageSize = this.pageSize
            params.date = this.date
            params.userId = this.schoolInfo.userId
            let res = await $activity.reportList(params)
            if (res.code === 200) {
                this.total = res.data.total
                this.tableList = res.data.records
            }
            this.dataListLoading = false
        },
        currentChange(e) {
            this.pageNum = e
            this.getTableData()
        },
        prevClick(e) {
            this.pageNum = e
            this.getTableData()
        },
        nextClick(e) {
            this.pageNum = e
            this.getTableData()
        },
        submit() {
            let params = {}
            params.userId = $cookies.get("userInfo").id
            params.userName = $cookies.get("userInfo").userName
            params.applyId = this.schoolInfo.id
            params.opinion = this.textarea
            params.score = this.numdata
            params.reportscore = this.lunnum
            console.log(params)
            $activity.review(params).then(res => {
                console.log(res)
                if (res.code === 200) {
                    this.$message.success('评分成功')
                    this.flag = false
                } else {
                    this.$message.error('系统错误,请联系管理员')
                }
            })
        },
    }
}
</script>

<style>
.el-form-item__label {
    font-size: 18px;
}
.el-divider__text {
    font-size: 24px;
}
.el-input__inner {
    font-size: 18px;
}
</style>